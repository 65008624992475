import { Box } from "@mui/system";

export default function MapPC() {
  return (
    <Box sx={{display:{xs:"none", sm:"none", md:"block", lg:"block", xl:"blolck" }}}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1617.9944023759788!2d24.027195238332922!3d49.842779591575564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473add7258bf7bbd%3A0x8d22d75bebda3123!2z0KTQvtC90YLQsNC9INCx0ZbQu9GPINCe0L_QtdGA0L3QvtCz0L4g0YLQtdCw0YLRgNGD!5e0!3m2!1suk!2sua!4v1696939490567!5m2!1suk!2sua"
        width="100%"
        height="600"
        style={{
          border: "none",
          paddingTop: "4%",
          bottom: 0,
        }}
        title="none"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </Box>
  );
}
